<template>
  <b-card-actions title="Filters" action-collapse collapsed>
    <b-card-text>
      <b-row>
        <b-col cols="12" md="2" className="mb-md-0 mb-2">
          <label>Status</label>
          <v-select
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-text>
  </b-card-actions>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol, BCardText, BFormDatepicker } from 'bootstrap-vue';
import vSelect from 'vue-select';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import store from '@/store';
import { onUnmounted, ref } from '@vue/composition-api';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormDatepicker,
    BCardActions,
    BCardText,
    vSelect,
  },
  data: () => {
    return {};
  },
  methods: {},
  props: {
    statusFilter: {
      type: [String, null],
      default: null,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    createAtStartFilter: {
      type: [String, null],
      default: null,
    },
    createAtEndFilter: {
      type: [String, null],
      default: null,
    },
  },
  mounted() {},
  setup(props, { emit }) {
    return {};
  },
};
</script>
