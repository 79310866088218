<template>
  <b-sidebar
    id="add-new-cohort-sidebar"
    :visible="isAddNewCohortSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-cohort-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">Add New Cohort</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(createReceivableCohort)" @reset.prevent="resetForm">
          <!-- First Name -->
          <validation-provider #default="validationContext" name="Name" rules="required">
            <b-form-group label="Name" label-for="name">
              <b-form-input
                id="name"
                v-model="cohortData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Cohort Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- program name -->
          <validation-provider #default="validationContext" name="Program Name">
            <b-form-group label="Program Name" label-for="program-name">
              <b-form-input
                id="program-name"
                v-model="cohortData.program.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Program Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Program start date -->
          <validation-provider #default="validationContext" name="Program Start Date">
            <b-form-group label="Program Start Date" label-for="programStartDate" :state="getValidationState(validationContext)">
              <flat-pickr
                id="programStartDate"
                v-model="cohortData.program.startDate"
                class="form-control"
                placeholder="n/a"
                :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'Z' }"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Program end date -->
          <validation-provider #default="validationContext" name="Program End Date">
            <b-form-group label="Program End Date" label-for="programStartDate" :state="getValidationState(validationContext)">
              <flat-pickr
                id="programEndDate"
                v-model="cohortData.program.endDate"
                class="form-control"
                placeholder="n/a"
                :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'Z' }"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Status -->
          <validation-provider #default="validationContext" name="Status" rules="required">
            <b-form-group label="Status" label-for="status" :state="getValidationState(validationContext)">
              <v-select
                v-model="cohortData.status"
                :options="statusOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="cohort-status"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button-group class="w-100">
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary" @click="hide"> Cancel </b-button>
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="createReceivableCohort(false)">
                Create
              </b-button>
              <b-button disabled v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" @click="createReceivableCohort(true)">
                Create + Edit
              </b-button>
            </b-button-group>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BButtonGroup,
  BLink,
  BFormDatepicker,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { onUnmounted, ref } from '@vue/composition-api';
import { required, alphaNum, email } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import store from '@/store';
import router from '@/router';
import { VueAutosuggest } from 'vue-autosuggest';
import useCohortsList from './useCohortsList';
import { useRouter } from '@core/utils/utils';
import flatPickr from 'vue-flatpickr-component';

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BButtonGroup,
    BLink,
    BFormDatepicker,
    vSelect,
    VueAutosuggest,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewCohortSidebarActive',
    event: 'update:is-add-new-cohort-sidebar-active',
  },
  props: {
    isAddNewCohortSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    };
  },
  methods: {
    async createReceivableCohort(follow) {
      if (!this.cohortData.name) return;

      store
        .dispatch('receivableStoreModule/addCohort', this.cohortData)
        .then((response) => {
          console.log(`created cohort`, response);

          const { data: cohort } = response;

          if (false && follow) {
            return this.$router.push({
              name: 'apps-accounting-receivable-cohort-edit',
              params: { id: cohort.id },
            });
          }

          if (false && this.$route.query.return) {
            return this.$router.push({
              name: this.$route.query.return,
              params: { id: payload.student },
              query: {
                op: 'session.assign',
                cohortId: cohort.id,
              },
            });
          }

          this.$emit('refetch-data');
        })
        .catch((error) => {
          console.error(`failed to create cohort`, error);
        })
        .finally(() => {
          this.$emit('update:is-add-new-cohort-sidebar-active', false);
        });
    },
  },
  mounted() {},
  setup(props, { emit }) {
    const blankCohortData = {
      name: '',
      program: {},
      status: 'created',
    };

    const cohortData = ref(JSON.parse(JSON.stringify(blankCohortData)));
    const resetCohortData = () => {
      cohortData.value = JSON.parse(JSON.stringify(blankCohortData));
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetCohortData);

    const { statusOptions } = useCohortsList();

    return {
      cohortData,
      // onSubmit,

      statusOptions,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style scoped lang="scss">
pre {
  min-height: 295px;
  padding: 1.5rem;
  margin-bottom: 0;
  border-radius: 0.5rem;
}

.attr-tag {
  float: right;
  margin: 2.5px 0 2.5px 5px;
}

#add-new-cohort-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
